import { PromptResult } from "@considr-it/storied-entities";
import { useGlobal } from "@considr-it/storied-shared";
import { useEffect, useState } from "react";

interface PromptLayerRefProps {
  promptName?: string;
  metaData: {};
}

export const PromptLayerRef = ({
  promptName,
  metaData,
}: PromptLayerRefProps) => {
  const { transport } = useGlobal();
  const [promptResultList, setPromptResultList] =
    useState<PromptResult[]>(null);

  useEffect(() => {
    transport
      .post("getPromptResult", { promptName, metaData })
      .then((resp) => setPromptResultList(resp.data));
  }, []);

  if (!promptResultList || promptResultList.length === 0) {
    return <></>;
  }

  return (
    <ul>
      {promptResultList.map((pr: PromptResult) => {
        return (
          <li key={pr.id}>
            <a
              href={`https://promptlayer.com/workspace/6284/request/${pr.promptLayerRequestId}`}
              target="_blank"
            >
              {pr.promptName} - {pr.modelName}
            </a>
          </li>
        );
      })}
    </ul>
  );
};
