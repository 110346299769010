import { Transport } from "@considr-it/storied-shared";
import { Auth0Provider } from "@auth0/auth0-react";
import { GlobalProvider } from "@considr-it/storied-shared";
import { PingResponse } from "@considr-it/storied-entities";
import { AdminAuth0Wrapper } from "./AdminApp";

export const AdminAppWrapper = () => {
  const { data: pingData } = Transport.useTransportSWR<PingResponse>(
    Transport.publicTransport,
    "ping",
    {
      refreshInterval: 120000,
    }
  );

  if (!pingData) {
    return null;
  }

  return (
    <Auth0Provider
      domain={pingData.oAuthDomain}
      clientId={pingData.oAuthId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: pingData.oAuthAudience,
        prompt: "login",
      }}
      cacheLocation={"localstorage"}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
    >
      <GlobalProvider pingData={pingData} isAdmin={true}>
        <AdminAuth0Wrapper />
      </GlobalProvider>
    </Auth0Provider>
  );
};

export default AdminAppWrapper;
