import { LiveStory, Topic } from "@considr-it/storied-entities";
import { useGlobal } from "@considr-it/storied-shared";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

export const TopicOrLiveStoryRedirect = () => {
  const { transport } = useGlobal();
  const { topicOrLiveStoryId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    transport.get<Topic>(`topic/${topicOrLiveStoryId}`).then((resp) => {
      if (resp?.data?.liveStory) {
        navigate(`/ls/${resp.data.liveStory}`);
      } else {
        transport
          .get<LiveStory>(`liveStory/${topicOrLiveStoryId}`)
          .then((resp2) => {
            if (resp2?.data) {
              navigate(`/ls/${resp2.data.id}`);
            } else {
              navigate("/");
            }
          });
      }
    });
  }, []);

  return <></>;
};
