import { LLMEvaluation, User } from "@considr-it/storied-entities";
import { useGlobal } from "@considr-it/storied-shared";
import { useEffect, useState } from "react";
import { PromptLayerRef } from "./PromptLayerRef";

export const LiveQuestionEvaluation = ({ liveQuestionId }) => {
  const [llmEvaluation, setLLMEvaluation] = useState<LLMEvaluation>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { transport } = useGlobal();

  const [reviewerComment, setReviewerComment] = useState<string>("");
  const [reviewerResult, setReviewerResult] = useState<string>("");
  const [reviewerAlternative, setReviewerAlternative] = useState<string>("");

  useEffect(() => {
    transport
      .get<LLMEvaluation>(`/llmEvaluation/${liveQuestionId}`)
      .then((resp) => {
        setLLMEvaluation(resp.data);
        setLoading(false);

        setReviewerComment(resp.data.reviewerComment);
        setReviewerResult(resp.data.reviewerResult);
        setReviewerAlternative(resp.data.reviewerAlternative);
      });
  }, []);

  const runEval = async () => {
    setLoading(true);

    const { data } = await transport.post(
      `/runLiveQuestionEval/${liveQuestionId}`
    );

    setLLMEvaluation(data);
    setLoading(false);
  };

  if (loading) {
    return <h6>Loading eval...</h6>;
  }

  if (!llmEvaluation) {
    return <button onClick={runEval}>Run Eval</button>;
  }

  return (
    <div>
      <h6
        style={{ color: llmEvaluation.llmResult === "good" ? "green" : "red" }}
      >
        LLM Result: {llmEvaluation.llmResult}
      </h6>
      <h6>LLM Comment: {llmEvaluation.llmComment}</h6>
      <h6>LLM Alternative: {llmEvaluation.llmAlternative}</h6>
      <br />
      <b>
        {!!llmEvaluation.owner
          ? (llmEvaluation.owner as User).profile.givenName
          : ""}
      </b>
      <h6>Result:</h6>
      <input
        type="text"
        onChange={(e) => {
          setReviewerResult(e.target.value);
        }}
        defaultValue={llmEvaluation.reviewerResult}
        value={reviewerResult}
      />
      <h6>Comment:</h6>
      <input
        type="text"
        onChange={(e) => {
          setReviewerComment(e.target.value);
        }}
        defaultValue={llmEvaluation.reviewerComment}
        value={reviewerComment}
      />
      <h6>Alternative:</h6>
      <input
        type="text"
        onChange={(e) => {
          setReviewerAlternative(e.target.value);
        }}
        defaultValue={llmEvaluation.reviewerAlternative}
        value={reviewerAlternative}
      />
      <br />
      <button
        disabled={
          !(
            reviewerComment != llmEvaluation.reviewerComment ||
            reviewerResult != llmEvaluation.reviewerResult ||
            reviewerAlternative != llmEvaluation.reviewerAlternative
          )
        }
        onClick={async () => {
          setLoading(true);

          const { data } = await transport.patch(
            `/llmEvaluation/${liveQuestionId}`,
            {
              reviewerComment,
              reviewerResult,
              reviewerAlternative,
            }
          );

          setLLMEvaluation(data);

          setLoading(false);
        }}
      >
        Save
      </button>
      <PromptLayerRef metaData={{ eval_liveQuestionId: liveQuestionId }} />
      <br />
    </div>
  );
};
