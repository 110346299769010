import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";

export const LiveStoryDetailsWrapper = styled.div`
  padding: 24px;
`;

export const TableWrapper = styled.div`
  padding: 24px;
  padding-bottom: 16px;
`;

export const PaginationWrapper = styled.div`
  display: grid;
  grid-template-columns: auto minmax(80px, 3fr) minmax(150px, 1fr) minmax(200px, 1fr);
  align-items: center;
  gap: 8px;
  padding: 24px;
  padding-top: 0;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const PageDataWrapper = styled.div`
  display: flex;
  gap: 8px;
  white-space: nowrap;
`;

export const InputGroupWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 8px;
`;

export const OutputCard = styled.div`
  display: flex;
  background: #FFF;
  border-radius: 10px;
  box-shadow: 0px 4px 21px 0 rgba(0,0,0,0.05);
  width: 70vw;
`;

export const AccordionWrap = styled.div`
  width: 70vw;
  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
  .accordion-item:hover {
    background: #fff;
  }
  .accordion-item.editor-tools .accordion-header button {
    height: 48px;
  }
  .accordion-header {
    padding: 0 16px;
  }
  .accordion-button {
    box-shadow: none !important;
    padding: 16px 4px;
    font-weight: 600;
    background-color: transparent;
  }
  .accordion-button.collapsed::after,
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ff6d34'%3e%3cpath fillRule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-btn-color);
    background-color: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  ul {
    padding-left: 40px;
  }
`;

export const OutputWrapper = styled.div`
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 24px;
  }
`;