import {
  Idea,
  Instruction,
  LiveQuestion,
  LiveStory,
  MagicOutline,
  Output,
  Question,
  SharedOutput,
  Topic,
  User,
} from "@considr-it/storied-entities";
import parse from "html-react-parser";
import removeMd from "remove-markdown";
import { QuestionType, RecordingObjectType } from "@considr-it/storied-enums";
import { useGlobal, usePlayer } from "@considr-it/storied-shared";
import { useEffect, useState } from "react";
import ReactDiffViewer from "react-diff-viewer-continued";
import { useParams, useNavigate } from "react-router-dom";
import { PromptLayerRef } from "./PromptLayerRef";
// Styles
import {
  AccordionWrap,
  LiveStoryDetailsWrapper,
  OutputWrapper,
} from "./components.style";
import { Accordion } from "react-bootstrap";
import { convertMarkdownToHTML } from "@considr-it/storied-utils";
import { LiveQuestionEvaluation } from "./LiveQuestionEvaluation";

export const LiveStoryDetails = () => {
  const { transport, pingData } = useGlobal();
  const { liveStoryId } = useParams();
  const {
    controls: { togglePlay },
    state: { paused },
  } = usePlayer();

  const [liveStory, setLiveStory] = useState<LiveStory>(null);
  const [topic, setTopic] = useState<Topic>(null);
  const [activeOutput, setActiveOutput] = useState<string>("");
  const [instructions, setInstructions] = useState<Instruction[]>([]);

  useEffect(() => {
    if (!!liveStoryId) {
      transport
        .get(`/liveStory/${liveStoryId}`)
        .then((resp) => setLiveStory(resp.data));
    }
  }, [liveStoryId]);

  useEffect(() => {
    if (liveStory?.topic) {
      transport
        .get(`topic/${liveStory.topic}`)
        .then((resp) => setTopic(resp.data));
    }
  }, [liveStory]);

  const replaceMultipleNewLines = (str: string) => {
    let newStr = str;

    newStr = newStr.replaceAll("    ", "");

    while (newStr?.includes("\n\n")) {
      newStr = newStr.replaceAll("\n\n", "\n");
    }

    return newStr;
  };

  useEffect(() => {
    if (topic) {
      transport
        .get<Instruction[]>(`/instructions/${topic.id}`)
        .then((resp) => setInstructions(resp.data));
    }
  }, [topic]);

  if (!liveStory || (!!liveStory.topic && !topic)) {
    return <h3>Loading livestory data!</h3>;
  }

  const magicOutline = liveStory.magicOutline as MagicOutline;

  return (
    <LiveStoryDetailsWrapper>
      <h5>
        Owner: {(liveStory.owner as User).profile?.name} -{" "}
        {(liveStory.owner as User).mainEmail}{" "}
      </h5>
      <br />
      <button
        onClick={async () => {
          await transport.patch(`liveStoryReview/${liveStoryId}`, {
            reviewed: true,
          });

          window.close();
        }}
      >
        Mark as reviewed
      </button>
      <br />
      <h2>Live Story {`- (${liveStory.id})`}</h2>
      <h6>IsDraft: {!!liveStory.isDraft ? "true" : "false"}</h6>
      {magicOutline && <h6>Magic Outline Type: {magicOutline.outlineType}</h6>}
      {!!liveStory.magicOutline && (
        <h6>
          Magic Outline Input: {magicOutline.inputText}
          <PromptLayerRef metaData={{ magicOutline: magicOutline.id }} />
        </h6>
      )}
      {!!liveStory.outline && <h6>Outline: {liveStory.outline}</h6>}
      {(liveStory.liveQuestions as LiveQuestion[]).map(
        (lq: LiveQuestion, index: number) => {
          return (
            <div key={lq.id}>
              <PromptLayerRef metaData={{ liveQuestionId: lq.id }} />
              {lq.id}
              <br />
              <b style={{ color: "red" }}>
                {lq.skipped && (
                  <>
                    SKIPPED
                    <br />
                  </>
                )}
              </b>
              <b
                style={{
                  color: lq.shortQuestion?.length > 45 ? "red" : "black",
                }}
              >
                Short: {lq.shortQuestion} - [{lq.shortQuestion?.length}]
              </b>
              <br />
              <b
                style={{
                  color: lq.questionContext?.length > 90 ? "red" : "black",
                }}
              >
                Context: {lq.questionContext} - [{lq.questionContext?.length}]
              </b>
              <br />
              <b
                style={{
                  color: lq.spokenQuestion?.length > 200 ? "red" : "black",
                }}
              >
                Spoken: {lq.spokenQuestion || "null"} - [
                {lq.spokenQuestion?.length}]
              </b>
              {index < liveStory.subTranscripts.length && (
                <p style={{ whiteSpace: "pre-line" }}>
                  {liveStory.subTranscripts[index]}
                </p>
              )}
              <br />
              {index > 0 && <LiveQuestionEvaluation liveQuestionId={lq.id} />}
              <br />
              --------------------------------------------------------------------------------
              <br />
              <br />
              <br />
            </div>
          );
        }
      )}
      {topic && (
        <div>
          <h2>Topic {`- (${topic.id})`}</h2>
          <button
            onClick={() => {
              togglePlay({
                itemId: topic.id,
                itemModel: RecordingObjectType.TOPIC,
              });
            }}
          >
            {topic.filePath && (paused ? "Play audio" : "Pause")}
          </button>
          <PromptLayerRef
            promptName="Cleanup_Transcript"
            metaData={{ topicId: topic.id }}
          />
          <PromptLayerRef
            promptName="Cleanup_Transcript_V2"
            metaData={{ topicId: topic.id }}
          />
          {topic.transcript}
          <br />
          <h3>Outputs</h3>
          <AccordionWrap>
            <Accordion
              activeKey={activeOutput}
              onSelect={(e) => setActiveOutput(e?.[0])}
            >
              {(topic.outputs as Output[]).map((o: Output, i: number) => {
                const oldHtml = convertMarkdownToHTML(o.originalText);
                const newHtml = convertMarkdownToHTML(o.text);

                const sharedOutput = o.sharedOutput as SharedOutput;
                return (
                  <div key={o.id}>
                    <b>
                      Output #{i + 1} {o.title}{" "}
                    </b>{" "}
                    {" ---> "}
                    {o.reason + (o.param ? ` (${o.param})` : "")}
                    {sharedOutput && " ---> "}
                    {sharedOutput && (
                      <a
                        href={`${pingData.appOrigin}/s/${sharedOutput?.shortId}`}
                      >
                        {pingData.appOrigin}/s/{sharedOutput?.shortId}
                      </a>
                    )}
                    {` - (${o.id})`}
                    {i > 0 && oldHtml !== newHtml && (
                      <Accordion.Item eventKey={String(i)}>
                        <Accordion.Header>{o.title}</Accordion.Header>
                        <Accordion.Body>
                          <ReactDiffViewer
                            oldValue={o.originalText}
                            newValue={o.text}
                            splitView={true}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                    <Accordion.Item eventKey={String(i)}>
                      <Accordion.Header>{o.title}</Accordion.Header>
                      <Accordion.Body>
                        <OutputWrapper>
                          {parse(convertMarkdownToHTML((o as Output).text))}
                        </OutputWrapper>
                      </Accordion.Body>
                    </Accordion.Item>
                    <PromptLayerRef metaData={{ outputId: o.id }} />
                  </div>
                );
              })}
            </Accordion>
          </AccordionWrap>
          <br />
          <h3>Questions</h3>

          {(topic.questions as Question[]).map((q: Question) => {
            let promptName = "";

            switch (q.type) {
              case QuestionType.ACTION:
                promptName = "Action_Question";
                break;
              case QuestionType.PERSPECTIVE:
                promptName = "Perspective_Question";
                break;
              case QuestionType.POP:
                promptName = "POP_Question";
                break;
            }

            return (
              <div key={q.id}>
                <PromptLayerRef
                  promptName={promptName}
                  metaData={{ topicId: topic.id }}
                />
                <p>{`Q: ${q.question} - (${q.id})`} </p>
                {q.answer && (
                  <p>
                    <button
                      onClick={() => {
                        togglePlay({
                          itemId: (q.answer as Idea).id,
                          itemModel: RecordingObjectType.IDEA,
                        });
                      }}
                    >
                      {(q.answer as Idea).filePath &&
                        (paused ? "Play audio" : "Pause")}
                    </button>
                    {`A: ${(q.answer as Idea).transcript} - (${
                      (q.answer as Idea).id
                    })`}
                  </p>
                )}
                <br />
              </div>
            );
          })}

          <PromptLayerRef
            promptName="Purpose_Proposal"
            metaData={{ topicId: topic.id }}
          />
          <h3>Ideas</h3>

          {(topic.ideas as Idea[]).map((i: Idea) => {
            return (
              <div key={i.id}>
                {i.filePath && (
                  <button
                    onClick={() => {
                      togglePlay({
                        itemId: i.id,
                        itemModel: RecordingObjectType.IDEA,
                      });
                    }}
                  >
                    {i.filePath && (paused ? "Play audio" : "Pause")}
                  </button>
                )}
                <p>{`${i.transcript} - ${i.type} - (${i.id})`}</p>
              </div>
            );
          })}

          <h3>Instructions</h3>

          {instructions.map((i: Instruction) => {
            return (
              <div key={i.id}>
                {i.magicWandText && "Magic Wand Text: " + i.magicWandText}
                {i.filePath && (
                  <button
                    onClick={() => {
                      togglePlay({
                        itemId: i.id,
                        itemModel: RecordingObjectType.LET_ME_EXPLAIN,
                      });
                    }}
                  >
                    {i.filePath && (paused ? "Play audio" : "Pause")}
                  </button>
                )}
                <p>{`${i.transcript} - (${i.id})`}</p>
                <br />
              </div>
            );
          })}
        </div>
      )}

      <br />
      <br />
      <br />
    </LiveStoryDetailsWrapper>
  );
};
