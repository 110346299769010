import {
  LiveStory,
  LiveStoryReview,
  Topic,
  User,
} from "@considr-it/storied-entities";
import { useGlobal } from "@considr-it/storied-shared";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Table as BTable, Form, Button, InputGroup } from "react-bootstrap";
// Styles
import {
  TableWrapper,
  PaginationWrapper,
  ButtonsWrapper,
  PageDataWrapper,
  InputGroupWrapper,
} from "./components.style";

type Record = {
  id: string;
  ownerMail: string;
  inferredWritingStyle: string;
  reviewed: boolean;
  setReviewedHandler: any;
  isTopic: boolean;
  questionsNum: number;
  answersNum: number;
  created: Date;
  customStartType: string;
  isDraft: boolean;
  reviewerEmail: string;
};

const columns: ColumnDef<Record>[] = [
  {
    header: "ID",
    accessorKey: "id",
    cell: (cellProps) => (
      <a
        href={`/ls/${cellProps.row.original.id}`}
        target="_blank"
        style={{
          color: cellProps.row.original?.isTopic ? "#4CBF05" : "#F12A2A",
        }}
      >
        {cellProps.row.original.id}
      </a>
    ),
  },
  { header: "Custom Start Type", accessorKey: "customStartType" },
  {
    header: "Inferred ws",
    accessorKey: "inferredWritingStyle",
  },
  { header: "Is Draft", accessorKey: "isDraft" },
  {
    header: "Q / A",
    accessorFn: (r) => `Q: ${r.questionsNum}, A: ${r.answersNum}`,
  },
  { header: "Owner Mail", accessorKey: "ownerMail" },
  { header: "Name", accessorKey: "ownerName" },
  { header: "Created", accessorKey: "created" },
  {
    header: "Reviewed",
    accessorKey: "reviewed",
    cell: (cellProps) => (
      <div>
        <input
          style={{ width: 16, height: 16, cursor: "pointer" }}
          type="checkbox"
          defaultChecked={cellProps.row.original.reviewed}
          onChange={cellProps.row.original.setReviewedHandler}
        />
        <h6>{cellProps.row.original?.reviewerEmail}</h6>
      </div>
    ),
  },
];

export const LiveStoryList = () => {
  const { transport } = useGlobal();
  const customStartFilter = useRef<string>("");
  const ownerMailFilter = useRef<string>("");
  const liveStoryIdFilter = useRef<string>("");
  const ownerNameFilter = useRef<string>("");

  const totalCountRef = useRef<number>(null);

  const [pageInfo, setPageInfo] = useState<{
    index: number;
    size: number;
  }>({ index: 0, size: 10 });

  const maxIndex = Math.max(
    Math.floor(totalCountRef.current / pageInfo.size) - 1,
    0
  );

  const [data, setData] = useState([]);
  const [liveStoriesReviewsList, setLiveStoriesReviewesList] =
    useState<LiveStoryReview[]>(null);
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const updatePageInfo = (index: number, size: number) => {
    if (index > maxIndex) {
      index = 0;
    }

    setPageInfo({
      index,
      size,
    });
  };

  const setReviewedHandler = (lsr: LiveStoryReview) => async (e) => {
    setLiveStoriesReviewesList((prevArray) => {
      const newArray = [...prevArray];
      const checkedLsr = newArray.find((_lsr) => _lsr.id === lsr.id);

      if (checkedLsr) {
        checkedLsr.reviewed = e.target.checked;
      }

      return newArray;
    });

    await transport.patch(
      `liveStoryReview/${(lsr.liveStory as LiveStory).id}`,
      {
        reviewed: e.target.checked,
      }
    );

    getData();
  };

  const getData = async () => {
    const { data } = await transport.post("/fetchLiveStories", {
      customStartFilter: customStartFilter.current,
      liveStoryIdFilter: liveStoryIdFilter.current,
      ownerMailFilter: ownerMailFilter.current,
      ownerNameFilter: ownerNameFilter.current,
      pageIndex: pageInfo.index,
      pageSize: pageInfo.size,
    });

    totalCountRef.current = data.totalCount;
    setLiveStoriesReviewesList(data.liveStoriesReviews);
  };

  useEffect(() => {
    getData();
  }, [pageInfo]);

  useEffect(() => {
    if (liveStoriesReviewsList) {
      const data = liveStoriesReviewsList.map((lsr: LiveStoryReview) => {
        const liveStory = lsr.liveStory as LiveStory;

        const user = liveStory.owner as User;
        let ownerMail = user?.mainEmail;
        let ownerName = user?.profile?.name;

        if (user?.isAnonymous) {
          ownerMail =
            user.loggedInHistory?.length > 0
              ? user.loggedInHistory[0]
              : ownerMail;
        }

        if (liveStory.id.toString() === "66e19c74684880f8c0055bfb") {
          console.log(liveStory.topic);
        }

        const data = {
          id: liveStory.id,
          ownerMail,
          ownerName,
          reviewed: lsr.reviewed,
          setReviewedHandler: setReviewedHandler(lsr),
          isTopic: !!liveStory.topic,
          questionsNum: liveStory.liveQuestions.length,
          answersNum: liveStory.subTranscripts.length,
          created: new Date(liveStory.created).toLocaleString(),
          customStartType: liveStory.customStartType,
          reviewerEmail: (lsr.owner as User)?.mainEmail,
          inferredWritingStyle: (liveStory.topic as Topic)
            ?.inferredWritingStyle,
          isDraft: liveStory.isDraft,
        };

        return data;
      }, []);
      setData(data);
    }
  }, [liveStoriesReviewsList]);

  if (!liveStoriesReviewsList) {
    return <h3>Loading livestory data!</h3>;
  }

  return (
    <>
      <br />
      <label>Custom Start Type</label>
      <input
        type={"text"}
        onChange={(e) => {
          customStartFilter.current =
            e.target?.value?.trim()?.toLowerCase() || "";
        }}
      ></input>
      <br />
      <br />

      <label>Live Story Id</label>
      <input
        type={"text"}
        onChange={(e) => {
          liveStoryIdFilter.current =
            e.target?.value?.trim()?.toLowerCase() || "";
        }}
      ></input>
      <br />
      <br />

      <label>Owner Name</label>
      <input
        type={"text"}
        onChange={(e) => {
          ownerNameFilter.current =
            e.target?.value?.trim()?.toLowerCase() || "";
        }}
      ></input>
      <br />
      <br />

      <label>Owner Email</label>
      <input
        type={"text"}
        onChange={(e) => {
          ownerMailFilter.current =
            e.target?.value?.trim()?.toLowerCase() || "";
        }}
      ></input>
      <br />
      <br />
      <button onClick={() => updatePageInfo(0, pageInfo.size)}>Find</button>
      <br />
      <button
        onClick={() => {
          window.location.reload();
        }}
      >
        Clear
      </button>

      <TableWrapper>
        <BTable striped bordered hover responsive>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </BTable>
      </TableWrapper>
      <PaginationWrapper>
        <ButtonsWrapper>
          <Button
            variant="outline-secondary"
            onClick={() => updatePageInfo(0, pageInfo.size)}
            disabled={pageInfo.index === 0}
          >
            {"<<"}
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => updatePageInfo(pageInfo.index - 1, pageInfo.size)}
            disabled={pageInfo.index === 0}
          >
            {"<"}
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => updatePageInfo(pageInfo.index + 1, pageInfo.size)}
            disabled={pageInfo.index === maxIndex}
          >
            {">"}
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => updatePageInfo(maxIndex, pageInfo.size)}
            disabled={pageInfo.index === maxIndex}
          >
            {">>"}
          </Button>
        </ButtonsWrapper>
        <PageDataWrapper>
          <div>Page</div>
          <strong>
            {pageInfo.index + 1} of {maxIndex + 1}
          </strong>
        </PageDataWrapper>
        <InputGroupWrapper>
          Go to page:
          <InputGroup>
            <Form.Control
              type="number"
              value={pageInfo.index + 1}
              onChange={(e) => {
                let page = e.target.value ? Number(e.target.value) - 1 : 0;

                if (page < 0) {
                  page = 0;
                }

                if (page > maxIndex) {
                  page = maxIndex;
                }

                updatePageInfo(page, pageInfo.size);
              }}
            />
          </InputGroup>
        </InputGroupWrapper>
        <Form.Select
          value={pageInfo.size}
          onChange={(e) => {
            updatePageInfo(0, Number(e.target.value));
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Form.Select>
      </PaginationWrapper>
    </>
  );
};
