import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  AccountProvider,
  useAccount,
  useGlobal,
} from "@considr-it/storied-shared";
import { useAuth0 } from "@auth0/auth0-react";
import { UserRole } from "@considr-it/storied-enums";
import { LiveStoryList } from "./components/LiveStoryList";
import { LiveStoryDetails } from "./components/LiveStoryDetails";
import { TopicOrLiveStoryRedirect } from "./components/TopicOrLiveStoryRedirect";
import { AdminAppStyled } from "./admin-app.style";

export const AdminApp = () => {
  const { account, isLoadingAccount, isValidatingAccount } = useAccount();
  const { isAuthenticated, logout, loginWithRedirect } = useAuth0();

  if (!account || isLoadingAccount || isValidatingAccount) {
    return <></>;
  }

  if (account.role !== UserRole.Admin) {
    return (
      <div>
        <button
          onClick={() => {
            if (isAuthenticated) {
              logout();
            } else {
              loginWithRedirect();
            }
          }}
        >
          {isAuthenticated ? "Logout" : "Login"}
        </button>
        <h1>You need to be an admin to view this page</h1>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<LiveStoryList />} />
        <Route
          path=":topicOrLiveStoryId"
          element={<TopicOrLiveStoryRedirect />}
        />
        <Route path="ls/:liveStoryId" element={<LiveStoryDetails />} />
      </Routes>
    </BrowserRouter>
  );
};

export const AdminAuth0Wrapper = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <></>;
  }

  return (
    <AccountProvider>
      <AdminAppStyled>
        <AdminApp />
      </AdminAppStyled>
    </AccountProvider>
  );
};
